<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-33.mp3"),
      },
      timeStampList: [5.0, 7.5, 10.2, 15.5, 21.2, 26.2, 32.4],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Yǎnjing, yǎnjing, ěrduo, ěrduo,",
            chineseWords: "眼睛、眼睛，耳朵、耳朵，",
          },
          {
            id: 2,
            spelling: "Zhè shì yǎnjing . Zhè shì ěrduo.",
            chineseWords: "这是眼睛。这是耳朵。",
          },
          {
            id: 3,
            spelling: "Wǒ de yǎnjing dà. Dà, dà, dà.",
            chineseWords: "我的眼睛大。大、大、大。",
          },
          {
            id: 4,
            spelling: "Wǒ de ěrduo xiǎo. Xiǎo, xiǎo, xiǎo.",
            chineseWords: "我的耳朵小。小、小、小。",
          },
          {
            id: 5,
            spelling: "Gǒu de yǎnjing dà. Dà dà dà.",
            chineseWords: "狗的眼睛大。大、大、大。",
          },
          {
            id: 6,
            spelling: "Māo de ěrduo xiǎo. Xiǎo xiǎo xiǎo.",
            chineseWords: "猫的耳朵小。小、小、小。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
